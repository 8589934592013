html,
body {
  font-family: PingFangSC-Regular;
  scroll-behavior: smooth;
}

.page {
  max-width: var(--max-width);
  margin: auto;
  overflow-x: hidden;
  position: relative;
  user-select: none;

  .adm-modal-content {
    padding: 0;
  }

  .adm-center-popup-body {
    border-radius: 0;
  }

  .adm-input-element,
  .adm-input {
    font-size: 24px;
  }

  /* bigImg大图页面重置antd-mobile样式 */
  .adm-center-popup-wrap {
    z-index: 9;
  }

  .adm-tabs-header {
    border-bottom: 0;
  }

  .adm-tabs-tab-active {
    color: var(--yellow);
  }

  .adm-modal-content {
    padding: 40px;
    width: 630px;
  }

  .adm-modal-footer-empty {
    height: 0;
  }

  .adm-modal-body:not(.adm-modal-with-image) {
    padding: 0;
  }
}

.adm-toast-mask .adm-toast-main-icon {
  padding: 24px !important;
  min-width: 130px !important;
}

.adm-toast-loading {
  --size: 56px !important;
}

.adm-popup-body-position-bottom {
  max-width: var(--max-width);
  left: 50% !important;
  margin-left: -375px;
}

.adm-toast-mask {
  z-index: 99999 !important;
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
}

:root:root {
  --primary: #002fa7;
  --highlight: #ffe600;
  --adm-color-primary: var(--primary);
  --tabbar-hight: 116px;
  --font-family: PingFangSC-Regular;
  --font-family-medium: PingFangSC-Medium;
  --font-family-bold: PingFangSC-Semibold;
  --black: #1e293b;
  --black02: #93a3b8;
  --black03: #191919;
  --bg-black: #f8f9fd;
  --bg-blue: #f2f5fa;
  --bg-gray: #f8f8f8;

  --red: #de2222;
  --bg-red: #fdeeee;
  --yellow: #ffe900;
  --adm-font-size-6: 28px;
  --adm-font-size-7: 30px;
  --max-width: 750px;

  --adm-center-popup-min-width: 100px;
  --adm-center-popup-max-width: 700px;
  --adm-color-light: var(--black02);
  --adm-color-weak: #000;
}

.toploading {
  transition: all 0.5s ease-in-out;
  width: 750px;
  height: 0;
  text-align: center;
  box-sizing: border-box;
}

.toploading2 {
  height: 46px;
  padding: 10px 0;
}

.domScrollKey {
  position: relative;
}
/* 
iPhone SE（SE, 5S, 5C）
Android One
 */
@media screen and (min-width: 320px) {
  html {
    font-size: 43px;
  }
}

@media screen and (min-width: 360px) {
  html {
    font-size: 48px;
  }
}

@media screen and (min-width: 375px) {
  html {
    font-size: 50px;
  }
}

@media screen and (min-width: 390px) {
  html {
    font-size: 52px;
  }
}

@media screen and (min-width: 411px) {
  html {
    font-size: 55px;
  }
}

@media screen and (min-width: 428px) {
  html {
    font-size: 57px;
  }
}

@media screen and (min-width: 480px) {
  html {
    font-size: 64px;
  }
}


/**首页的底部引流弹层**/
.homepopup {
  .adm-popup-body{
    background:none;
  }
}

.listModal{
  .adm-modal-body{
    background:none;
    max-height : 90vh;
  }
  .adm-modal-content{
    max-height : 90vh;
    width:auto;
  }
  .adm-center-popup-wrap{
    margin-top:-58px;
  }
   
}